import { EContractStatus, FilteredCreditApplication } from '@src/api/credit-api'
import {
  applicantFullName,
  areAllDocumentsReadyForCVT,
  hasMissingDocuments,
} from '@src/data/credit-application-selectors'
import { Trans, useTranslation } from 'react-i18next'
import { Constants, EApplicantType, EConsignoSignatureStatus, EWorksheetStatus } from '@src/types'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useRequiredDocumentsWithStatus } from '@src/data/credit-application-hooks'
import { useGetContractIframeUrl } from '@src/api/contract-api'
import { Link } from 'react-router-dom'
import CreditStep, { CreditStepStatus } from './CreditStep'
import { useIsReportExpired, contractIsReceived } from '../../hooks'

interface Props {
  creditApp: FilteredCreditApplication
}

const WorksheetCreditStep = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const reportExpired = useIsReportExpired(creditApp.hardHitReportReceivedOn)
  const requiredDocumentsWithStatus = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)
  const isContractReadyOrUnderReview =
    creditApp.contract.status === EContractStatus.Ready || creditApp.contract.status === EContractStatus.UnderReview
  const [shouldPoll, setShouldPoll] = useState(isContractReadyOrUnderReview)
  const [iframeDto, isLoadingLink, failureCount] = useGetContractIframeUrl(creditApp.id, shouldPoll)

  useEffect(() => {
    if (failureCount >= 4) setShouldPoll(false)
  }, [iframeDto, failureCount])

  const worksheetWaitContract =
    !creditApp.allIncomesConfirmed ||
    !creditApp.allTaskCompletedForCVT ||
    !areAllDocumentsReadyForCVT(requiredDocumentsWithStatus)

  const contractReceived = contractIsReceived(EApplicantType.Applicant, creditApp)

  const contractRefused = contractReceived && creditApp.contract.status === EContractStatus.Declined

  const signatureReceived = creditApp.signers.some(
    (x) => x.isApplicant && x.status === EConsignoSignatureStatus.Completed,
  )

  const hideLinkToDetail =
    contractRefused || isLoadingLink || reportExpired || signatureReceived || worksheetWaitContract

  const { status, content, link } = useMemo<{ status: CreditStepStatus; content?: ReactNode; link?: string }>(() => {
    if (hasMissingDocuments(creditApp)) return { status: CreditStepStatus.NotReady }

    if (reportExpired) {
      return {
        status: CreditStepStatus.RequiresAttention,
        link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
        content: (
          <Trans
            i18nKey="overview.contract90Days"
            values={{
              phoneNumber: Constants.PhoneNumber,
            }}
            components={{
              a: <Link to={Constants.PhoneNumberLink} style={{ textWrap: 'nowrap', textDecoration: 'underline' }} />,
            }}
          />
        ),
      }
    }

    if (worksheetWaitContract) return { status: CreditStepStatus.Await, content: t('overview.worksheetWaitContract') }

    if (contractIsReceived(EApplicantType.Applicant, creditApp) && !contractRefused)
      return { status: CreditStepStatus.Completed }

    if (contractRefused) {
      return {
        status: CreditStepStatus.Await,
        content: t('contract.refused'),
      }
    }

    if (hideLinkToDetail) {
      return {
        status: CreditStepStatus.Await,
        content: t('worksheet.waitingContract'),
      }
    }
    return {
      status: CreditStepStatus.RequiresAttention,
      content: t('overview.worksheetContent'),
      link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
    }
  }, [creditApp, reportExpired, worksheetWaitContract, t, contractRefused, hideLinkToDetail])

  return (
    <CreditStep
      status={status}
      linkToDetail={link ?? ''}
      title={t('worksheet.title', { name: applicantFullName(creditApp.applicant) })}
      content={content ?? ''}
      hideLinkToDetail={hideLinkToDetail}
    />
  )
}

export default WorksheetCreditStep
