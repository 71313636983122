import { IFINANCE_ID, MEDICARD_ID } from '@src/data/merchant-selectors'
import { isMedicardWebSite } from '@src/services/query-string'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  reason: string
}

interface Information {
  title: string
  message: string
}

const MerchantMissingPage = ({ reason }: Props) => {
  const { t, i18n } = useTranslation()
  const [information, setInformation] = React.useState<Information | null>(null)
  const [baseUrl, setBaseUrl] = React.useState<string>('')

  const buildBaseUrl = () => {
    const url = window.location.href
    let currentBaseUrl = url.split('/get-merchant-error')[0]
    if (isMedicardWebSite()) currentBaseUrl = `${currentBaseUrl}/${MEDICARD_ID}`
    else currentBaseUrl = `${currentBaseUrl}/${IFINANCE_ID}`
    setBaseUrl(currentBaseUrl)
  }

  useEffect(() => {
    let errorPageInformation: Information | null = null
    switch (reason) {
      case 'merchantNotFound':
        errorPageInformation = {
          title: t('merchant.merchantNotFound'),
          message: t('merchant.merchantNotFoundMessage'),
        }
        break
      case 'merchantInactive':
        errorPageInformation = {
          title: t('merchant.merchantInactive'),
          message: t('merchant.merchantInactiveMessage'),
        }
        break
      default:
        errorPageInformation = {
          title: t('common.notFound'),
          message: t('common.unexpectedError'),
        }
        break
    }
    buildBaseUrl()
    setInformation(errorPageInformation)
  }, [reason, i18n.language, t])

  return (
    <main className="general-message">
      <h4 className="subtitle">{information?.title}</h4>
      <h1>{t('common.oopsTitle')}</h1>
      <div className="paragraph">
        <p style={{ fontWeight: 'bold' }}>{information?.message}</p>
        <p style={{ fontWeight: 'normal' }}>{t('merchant.contactUs.title')}</p>
        <ul style={{ listStyle: 'disc', margin: '0 1.5em' }}>
          <li>
            {t('merchant.contactUs.line_1')}
            <a href="tel:1-888-689-9876" style={{ textDecoration: 'none', color: '#08abf0' }}>
              1&#x2011;888&#x2011;689&#x2011;9876
            </a>{' '}
          </li>
          <li>
            <Trans i18nKey="merchant.contactUs.line_2">
              Complete a{' '}
              <a style={{ color: '#08abf0' }} href={baseUrl}>
                generic credit application
              </a>{' '}
              indicating the name of the merchant for which you wish to obtain financing.
            </Trans>
          </li>
        </ul>
      </div>
    </main>
  )
}

export default MerchantMissingPage
