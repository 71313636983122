import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepperHeaderStep from './StepperHeaderStep'
import './stepper.css'
import { Noop, StepperContext, StepperStepOptions, useStepper } from './stepper.service'

interface StepperProps {
  submitLabel?: string
  children: ReactNode
}

const StepperBase = ({ children, submitLabel }: StepperProps) => {
  const { t } = useTranslation()
  const { current, steps, currentStep, currentPosition, onSubmit, isSubmitting, setNext, setPrevious } = useStepper()
  const hasPrevious = Boolean(setPrevious)
  const hasNext = Boolean(setNext)

  const handleSubmit = () => {
    if (onSubmit) onSubmit()
  }

  return (
    <div>
      <div>
        <ul className="stepper__header">
          {steps.map(({ step, ...stepProps }, index) => (
            <StepperHeaderStep key={step} {...stepProps} position={index + 1} selected={step === current} />
          ))}
        </ul>
      </div>
      <section className="stepper__content">{children}</section>
      {!currentStep?.hideControl && (
        <footer className="stepper__footer">
          <div className="stepper__action_wrapper">
            {hasPrevious && (
              <button type="button" className="btn btn-grey" onClick={setPrevious}>
                <i className="fa-light fa-arrow-left" />
                <span>{t('common.back')}</span>
              </button>
            )}
          </div>
          <div className="stepper__count">
            <span>
              {currentPosition}&nbsp;/&nbsp;{steps.length}
            </span>
          </div>
          <div className="stepper__action_wrapper">
            {hasNext && (
              <button
                type="button"
                className="btn btn-blue btn-simple"
                disabled={currentStep?.invalid || currentStep?.loading}
                onClick={setNext}
              >
                <span>{t('common.next')}</span>
                <i className="fa-regular fa-arrow-right" />
              </button>
            )}
            {!hasNext && (
              <button
                type="button"
                className="btn btn-blue btn-simple"
                disabled={currentStep?.invalid || currentStep?.loading || isSubmitting}
                onClick={handleSubmit}
              >
                {submitLabel || t('common.submit')}
              </button>
            )}
          </div>
        </footer>
      )}
    </div>
  )
}

interface StepperProviderProps {
  current: string
  submitLabel?: string
  setCurrentStep: (step: string) => void
  onSubmit?: () => void
  isSubmitting?: boolean
  children: ReactNode
}

const Stepper = ({
  children,
  current,
  setCurrentStep,
  onSubmit,
  isSubmitting = false,
  submitLabel,
}: StepperProviderProps) => {
  const [steps, setSteps] = useState<StepperStepOptions[]>([])

  const setStep = (options: StepperStepOptions) => {
    setSteps((state) => {
      const step = state.find((s) => s.step === options.step)
      if (step) {
        Object.assign(step, options)
        return [...state]
      }
      return [...state, options]
    })
  }

  const { currentStep, currentPosition, currentIndex } = useMemo(() => {
    const stepIndex = steps.findIndex((s) => s.step === current)
    const stepFound = stepIndex !== -1
    return {
      currentStep: stepFound ? steps[stepIndex] : undefined,
      currentIndex: stepFound ? stepIndex : undefined,
      currentPosition: stepFound ? stepIndex + 1 : undefined,
    }
  }, [current, steps])

  const { setNext, setPrevious } = useMemo(() => {
    let pSetNext: Noop | undefined
    let pSetPrevious: Noop | undefined
    if (currentIndex !== undefined) {
      for (let i = currentIndex + 1; i < steps.length; i += 1) {
        if (!steps[i].disabled) {
          pSetNext = () => setCurrentStep(steps[i].step)
          break
        }
      }
      for (let i = currentIndex - 1; i >= 0; i -= 1) {
        if (!steps[i].disabled) {
          pSetPrevious = () => setCurrentStep(steps[i].step)
          break
        }
      }
    }

    return { setNext: pSetNext, setPrevious: pSetPrevious }
  }, [currentIndex, setCurrentStep, steps])

  useEffect(() => {
    if (currentStep?.disabled && setNext) setNext()
  }, [currentStep?.disabled, setNext])

  const ctxValue = useMemo(
    () => ({
      current,
      setCurrentStep,
      setStep,
      setNext,
      setPrevious,
      steps,
      onSubmit,
      isSubmitting,
      currentStep,
      currentPosition,
    }),
    [current, setCurrentStep, setNext, setPrevious, steps, onSubmit, isSubmitting, currentStep, currentPosition],
  )

  return (
    <StepperContext.Provider value={ctxValue}>
      <StepperBase submitLabel={submitLabel}>{children}</StepperBase>
    </StepperContext.Provider>
  )
}

export default Stepper
