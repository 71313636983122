import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

export interface HolidaysDto {
  holidays: Date[]
}

export interface HolidaysForYear {
  dates: Date[]
}

const SCOPEHOLIDAYS = 'holidays'

const keysFactory = {
  holidays: (year: number) => [{ scope: SCOPEHOLIDAYS, year }] as const,
}

const GetHolidaysForYear = async ({
  queryKey: [{ year }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['holidays']>>) => {
  const response = await apiClient.get<HolidaysDto>(`api/Holidays/${encodeURIComponent(year)}`)

  return response.data
}

export function useGetHolidaysForYear(year: number): [Date[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.holidays(year),
    queryFn: GetHolidaysForYear,
  })

  return [data as unknown as Date[], isFetching]
}
