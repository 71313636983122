import { Constants } from '@src/types'
import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import * as yup from '@src/types/schemas/common/yup-extended'

export const buildEditSinAndLoanAmountSchema = (maxLoanAmount: number) => {
  return yup.default.object({
    id: yup.default.string().default(''),
    needsApplicantSin: yup.default.bool(),
    needsCoapplicantSin: yup.default.bool(),
    applicantSin: yup.default
      .string()
      .nullable()
      .when('needsApplicantSin', {
        is: true,
        then: (schema) => schema.required().SinType(),
      }),
    coapplicantSin: yup.default
      .string()
      .nullable()
      .when('needsCoapplicantSin', {
        is: true,
        then: (schema) => schema.required().SinType(),
      }),
    requestedLoanAmount: yupExtInt.double.required().default(0).min(Constants.MinLoanAmount).max(maxLoanAmount),
  })
}

export type EditSinAndLoanAmount = yup.default.InferType<ReturnType<typeof buildEditSinAndLoanAmountSchema>>
