import { EOriginSystemId, FilteredCreditApplication } from '@src/api/credit-api'
import calculateMonthlyAmount from '@src/data/compute-selectors'
import { hasMissingDocuments, hasSignedContract } from '@src/data/credit-application-selectors'
import { Constants, ECreditApplicationStatus, EDecision, EPrequalificationDecision } from '@src/types'
import { useMemo } from 'react'

export const useComputeMinMaxPaymentForLoanAmount = (
  creditApp: FilteredCreditApplication,
  loanAmount: number,
): [number, number] => {
  return useMemo(() => {
    const value = parseFloat(loanAmount.toString())
    if (value > 0 && creditApp.prequalificationDecision) {
      const baseTerm = Math.min(
        creditApp.applicant.age >= Constants.UpperThresholdAgeForApplicant
          ? Constants.CorrectedMaxTerm
          : Constants.BaseMaxTerm,
        creditApp.requestedLoanAmount <= Constants.AmountThresholdForLowerTerm
          ? Constants.MaxTermUnderAmountThreshold
          : Constants.BaseMaxTerm,
      )
      const minPayment = calculateMonthlyAmount(
        baseTerm,
        creditApp.prequalificationDecision.minInterestRate,
        value,
        Constants.FinanceFeeRateForRegular, // a changer pour deferred
      )
      const maxPayment = calculateMonthlyAmount(
        creditApp.applicant.age >= Constants.UpperThresholdAgeForApplicant
          ? Constants.CorrectedMaxTerm
          : Constants.BaseMaxTerm,
        creditApp.prequalificationDecision.maxInterestRate > 0
          ? creditApp.prequalificationDecision.maxInterestRate
          : Constants.MaxRate,
        value,
        Constants.FinanceFeeRateForRegular, // a changer pour deferred
      )
      return [minPayment, maxPayment]
    }
    return [0, 0]
  }, [creditApp, loanAmount])
}

export const isServerAnalysingCredit = (creditApp: FilteredCreditApplication | null): boolean => {
  return (
    creditApp !== null &&
    ((creditApp.status === ECreditApplicationStatus.Active && creditApp.prequalificationDecision == null) ||
      (creditApp.status === ECreditApplicationStatus.Active &&
        creditApp.prequalificationDecision?.decision === EPrequalificationDecision.Approved &&
        creditApp.normsDecision === null &&
        creditApp.consentHardHit === true))
  )
}

export const canPollCreditApp = (creditApp: FilteredCreditApplication | null): boolean => {
  if (creditApp) {
    let maxTime = new Date()
    const thirtySeconds = 30 * 1000
    maxTime = new Date(maxTime.getTime() - thirtySeconds)
    const creditAppTime = new Date(creditApp.updatedOn)
    return creditAppTime >= maxTime
  }
  return false
}

export const hasDecisionningError = (creditApp: FilteredCreditApplication | null): boolean => {
  if (creditApp) {
    const isServerDecisionning = isServerAnalysingCredit(creditApp)
    const canPoll = canPollCreditApp(creditApp)

    return (isServerDecisionning && !canPoll) || creditApp.finalDecision?.decision === EDecision.Error
  }
  return false
}

export type HighLevelPage =
  | 'loading' // loading the app is loading
  | 'notFound' // id does not exists
  | 'analysis-running' // server is analysing prequal or hard hit
  | 'decisionning-error' // either takes more time than normal to make a decision, has a decision error
  | 'prequal-result' // we have a prequalification result to display (no credit report, approved, declined)
  | 'hardhit-result' // we have a hard hit that yields a non approved final decision (error, declined, manual-revision, etc)
  | 'first-approval' // we have a first hard hit result to display (meaning the customer never got an approved decision and never clicked Get that loan)
  | 'approved' // the customer clicked "get that loan" and final decision is approved
  | 'contract'
  | 'funding' // the contract has been signed
  | 'closed' // either cancelled or completed
  | 'cancelling' // the client wanted to cancel their app but asked an agent to contact them
  | 'hardHit-NoCreditReport' // request was made via backoffice and we have the consent for soft and hardhit but no credit report
export const computeHighLevelPage = (
  creditApp: FilteredCreditApplication | null,
  isFetchingCreditApp: boolean,
): HighLevelPage => {
  if (!creditApp) {
    if (isFetchingCreditApp) return 'loading'
    return 'notFound'
  }

  if (
    creditApp.status === ECreditApplicationStatus.Cancelled ||
    creditApp.status === ECreditApplicationStatus.Completed
  )
    return 'closed'
  if (creditApp.applicantWantsToCancel) return 'cancelling'
  if (isServerAnalysingCredit(creditApp)) return 'analysis-running'
  if (
    creditApp.consentHardHit === true &&
    !creditApp.applicant.hasCreditReport &&
    !creditApp.coapplicant?.hasCreditReport
  )
    return 'hardHit-NoCreditReport'
  if (hasDecisionningError(creditApp)) return 'decisionning-error'
  if (creditApp.consentHardHit === false) return 'prequal-result'
  if (
    creditApp.finalDecision.decision === EDecision.Approved &&
    creditApp.worksheet === null &&
    creditApp.originSystemId !== EOriginSystemId.MerchantDashboard
  )
    return 'first-approval'
  if (hasSignedContract(creditApp) && !hasMissingDocuments(creditApp)) return 'funding'
  if (creditApp.finalDecision.decision === EDecision.Approved) return 'approved'
  return 'hardhit-result'
}
