import { MutationFunction, useQueryClient, useMutation } from '@tanstack/react-query'
import { Address } from '@src/containers/SubmitNewApp/components/PrequalificationSchema'
import { AxiosRequestConfig } from 'axios'
import addressApiClient from './addressApi-client'
import apiClient from './api-client'

const SCOPE = 'address'
const DETAIL = 'detail'
let currentSessionToken = ''

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

export interface SelectedAddress {
  placeId: string
}

export interface AddressSuggestionDto {
  addressInput: string
  recaptchaToken: string
}

export interface SuggestionsResponseDto {
  suggestions: Suggestion[]
  sessionToken: string
}

export interface Suggestion {
  addressSuggestion: string
  placeId: string
}

interface JwtSecurityToken {
  exp: number
}

const decodeJwt = (token: string): JwtSecurityToken => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  const decodedString = atob(base64)

  const payload = JSON.parse(decodedString) as JwtSecurityToken

  const jwtToken: JwtSecurityToken = {
    exp: payload.exp,
  }

  return jwtToken
}

const getSessionToken = async (recaptcha: string): Promise<string> => {
  if (currentSessionToken) {
    try {
      const decoded = decodeJwt(currentSessionToken)
      const expirationDate = new Date(decoded.exp * 1000)
      const nowUTC = new Date(new Date().toISOString())

      if (nowUTC < expirationDate) {
        return currentSessionToken
      }
    } catch (error) {
      console.error('Failed to decode JWT:', error)
    }
  }

  const options: AxiosRequestConfig = {
    headers: { 'x-recaptcha-token': recaptcha },
  }

  const response = await apiClient.post<string>('api/startSession', {}, options)
  const newToken = response.data

  currentSessionToken = newToken
  return currentSessionToken
}

const getAddressSuggestion: MutationFunction<SuggestionsResponseDto, AddressSuggestionDto> = async (
  dto: AddressSuggestionDto,
) => {
  const bearerToken = await getSessionToken(dto.recaptchaToken)

  const filteredDto = {
    addressInput: dto.addressInput,
    sessionToken: bearerToken,
  }

  const options: AxiosRequestConfig = {
    headers: { Authorization: `bearer ${bearerToken}` },
  }

  const response = await addressApiClient.post(`api/Address/Suggestions`, filteredDto, options)
  return response.data as SuggestionsResponseDto
}

export function useAddressSuggestion(): [
  MutationFunction<SuggestionsResponseDto, AddressSuggestionDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: getAddressSuggestion,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.sessionToken), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export interface AddressDetailDto {
  placeId: string
  recaptchaToken: string
  userLanguageCode: string
}

export interface DetailResponseDto {
  address: Address
  sessionToken: string
}

const addressDetail: MutationFunction<DetailResponseDto, AddressDetailDto> = async (dto: AddressDetailDto) => {
  const bearerToken = await getSessionToken(dto.recaptchaToken)

  const bearer = `bearer ${bearerToken}`

  const options: AxiosRequestConfig = {
    headers: {
      Authorization: bearer,
    },
  }

  const filteredDto = {
    placeId: dto.placeId,
    userLanguageCode: dto.userLanguageCode,
    sessionToken: bearerToken,
  }

  const response = await addressApiClient.post(`api/Address/Details`, filteredDto, options)
  return response.data as DetailResponseDto
}

export function useAddressDetail(): [MutationFunction<DetailResponseDto, AddressDetailDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: addressDetail,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.sessionToken), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}
