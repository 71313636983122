import { useGetContractIframeUrl } from '@src/api/contract-api'
import { EContractStatus, FilteredCreditApplication } from '@src/api/credit-api'
import {
  applicantFullName,
  areApplicantIncomesAreUnderReview,
  hasMissingDocuments,
} from '@src/data/credit-application-selectors'
import { EApplicantType, RequiredDocumentWithStatus } from '@src/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { contractIsReceived } from '../../hooks'
import CreditStep, { CreditStepStatus } from './CreditStep'

interface ContractCreditStepProps {
  applicantType: EApplicantType
  creditApp: FilteredCreditApplication
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
  noPaymentPolicy: boolean
}
const ContractCreditStep = ({
  creditApp,
  skipBankAccountRequiredDocuments,
  applicantType,
  noPaymentPolicy,
}: ContractCreditStepProps) => {
  const { t } = useTranslation()

  const docStepDone = !hasMissingDocuments(creditApp)
  const applicant = applicantType === EApplicantType.Applicant ? creditApp.applicant : creditApp.coapplicant!

  const applicantIncomeStepDone =
    areApplicantIncomesAreUnderReview(creditApp, EApplicantType.Applicant, skipBankAccountRequiredDocuments) ||
    creditApp.applicant.areIncomesConfirmed

  const coapplicantIncomeStepDone =
    creditApp.coapplicant === null ||
    creditApp.coapplicant.areIncomesConfirmed ||
    areApplicantIncomesAreUnderReview(creditApp, EApplicantType.CoApplicant, skipBankAccountRequiredDocuments)

  const contractReceived = contractIsReceived(applicantType, creditApp)

  const isContractReady = creditApp.contract.status === EContractStatus.Ready

  const contractRefused = contractReceived && creditApp.contract.status === EContractStatus.Declined

  const [shouldPoll, setShouldPoll] = useState(isContractReady)

  let content = ''
  let status = isContractReady ? CreditStepStatus.RequiresAttention : CreditStepStatus.NotReady

  const [iframeDto, isLoadingLink, failureCount] = useGetContractIframeUrl(creditApp.id, shouldPoll)

  useEffect(() => {
    if (iframeDto || failureCount >= 4) setShouldPoll(false)
  }, [iframeDto, failureCount])

  const urlsDto = iframeDto?.urls?.find((x) => x.isForApplicant === (applicantType === EApplicantType.Applicant))

  const hideLinkToDetail = contractReceived || !creditApp.cvtNumber || isLoadingLink || !urlsDto

  const hasCoApplicant = creditApp.coapplicant != null

  const applicantHasCompleted =
    iframeDto &&
    ((!urlsDto && iframeDto.completedSignatures?.length === 1) ||
      (!hasCoApplicant && iframeDto.completedSignatures?.length > 0) ||
      iframeDto.completedSignatures?.length > 1)

  if (
    creditApp.contract.status === EContractStatus.NotReady &&
    docStepDone &&
    applicantIncomeStepDone &&
    coapplicantIncomeStepDone
  ) {
    status = CreditStepStatus.Await
    content = noPaymentPolicy ? t('overview.noPaymentPolicyContractWait') : t('overview.contractWait')
  } else if (isContractReady || urlsDto) {
    content = urlsDto ? t('overview.contractReady') : ''
    if (applicantHasCompleted) {
      status = CreditStepStatus.Completed
    }
  } else if (contractRefused) {
    status = CreditStepStatus.Await
    content = `${t('contract.refused')}`
  } else if (contractReceived) {
    status = CreditStepStatus.Completed
    content = `${t('contract.received')} ${t('contract.review')}`
  }

  return (
    <CreditStep
      isPending={shouldPoll}
      status={status}
      linkToDetail={urlsDto?.url ?? ''}
      hideLinkToDetail={hideLinkToDetail}
      title={t('overview.contractStep', { name: applicantFullName(applicant) })}
      content={content}
    />
  )
}

export default ContractCreditStep
