import { useGetContractIframeUrl } from '@src/api/contract-api'
import { EContractStatus, useGetCreditApplicationById } from '@src/api/credit-api'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EWorksheetStatus } from '@src/types'
import { Loader } from '@src/components'
import { contractIsReceived } from '../ViewCreditApplication/HardHitApprovedResultPage/hooks'
import ErrorPage from '../ErrorPage'

interface Props {
  creditAppId: string
}

const ContractWaitingLoader = ({ creditAppId }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [shouldPollCreditApp, setShouldPollCreditApp] = useState(true)
  const [shouldPollContract, setShouldPollContract] = useState(false)
  const [creditApp] = useGetCreditApplicationById(creditAppId, shouldPollCreditApp, 15000)
  const [iframeDto, , failureCount] = useGetContractIframeUrl(creditAppId, shouldPollContract)

  if (creditApp && (creditApp.worksheet?.status === EWorksheetStatus.Draft || contractIsReceived(null, creditApp))) {
    navigate(`/creditapplication/${creditAppId}`)
  }

  useEffect(() => {
    if (creditApp?.contract.status === EContractStatus.Ready) {
      setShouldPollCreditApp(false)
      setShouldPollContract(true)
    }
  }, [creditApp?.contract.status])

  useEffect(() => {
    // Setting max time to pull creditApp
    const timeoutPollCreditApp = setTimeout(() => {
      setShouldPollCreditApp(false)
    }, 60000) // 1 minute

    return () => {
      clearTimeout(timeoutPollCreditApp)
    }
  }, [])

  const urlsDto = useMemo(() => iframeDto?.urls?.find((x) => x.isForApplicant === true), [iframeDto?.urls])

  useEffect(() => {
    if (urlsDto?.url) window.location.href = urlsDto.url
  }, [navigate, urlsDto?.url])

  if (failureCount === 6 || (!shouldPollCreditApp && creditApp?.contract.status !== EContractStatus.Ready))
    return <ErrorPage title={t('contract.errorMessage')} />

  return <Loader title={t('contract.waiting')} description={t('contract.waitingDescription')} />
}

export default ContractWaitingLoader
