import { MerchantPaymentPlan } from '@src/api/credit-api'
import { Constants } from '@src/types'
import { t } from 'i18next'

interface Props {
  merchantPaymentPlan: MerchantPaymentPlan | null
  requestedLoanAmount: number
  minInterestRate: number
  hasCoapplicant: boolean
}

const ApprovedSoftHitAndHardHitFooter = ({
  merchantPaymentPlan,
  requestedLoanAmount,
  minInterestRate,
  hasCoapplicant,
}: Props) => {
  const termBasedOnAmountRequested =
    merchantPaymentPlan?.loanTerm ??
    (requestedLoanAmount <= 3000
      ? Constants.MaxTermUnderAmountThreshold
      : requestedLoanAmount > 10000
        ? Constants.LongTerm
        : Constants.BaseMaxTerm)

  const interestRate = merchantPaymentPlan?.reducedInterestRate ?? minInterestRate
  const lenderFeeNotice =
    merchantPaymentPlan?.borrowerFeeRate === 0 ? '' : t('prequalification.priorToQualifyNotes.lenderFeeNotice')

  const getPromoText = () => {
    if (!merchantPaymentPlan || merchantPaymentPlan.reducedRateDurationInMonths === merchantPaymentPlan.loanTerm)
      return ''
    return t('prequalification.priorToQualifyNotes.promoReducedRateDurationPartOfLoan', {
      interestRate: merchantPaymentPlan.interestRate,
      reducedRateDurationInMonths: merchantPaymentPlan.reducedRateDurationInMonths,
    })
  }

  const promotext = getPromoText()

  const interestRateQualified = merchantPaymentPlan?.reducedInterestRate
    ? t('prequalification.priorToQualifyNotes.promotionalCodeQualifiedInterestRate')
    : t('prequalification.priorToQualifyNotes.clientQualifedInterestRate')
  const footerOne = t('prequalification.priorToQualifyNotes.1', {
    term: termBasedOnAmountRequested,
    interestRate,
    lenderFeeNotice,
    promotext,
    interestRateQualified,
  })
  const footerTwo = t(
    hasCoapplicant
      ? 'prequalification.priorToQualifyNotes.creditReportWithCoapplicant'
      : 'prequalification.priorToQualifyNotes.creditReport',
  )

  return (
    <div style={{ borderTop: '1px solid black', marginTop: '5rem' }}>
      <span style={{ fontSize: '1.25rem', lineHeight: '1.45rem' }}>
        <p style={{ marginTop: '2rem' }}>
          <span style={{ color: 'red' }}>¹</span>
          {footerOne}
        </p>
        <p style={{ marginTop: '2rem' }}>
          <span style={{ color: 'red' }}>²</span>
          {footerTwo}
        </p>
      </span>
    </div>
  )
}
export default ApprovedSoftHitAndHardHitFooter
