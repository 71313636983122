import { useTranslation } from 'react-i18next'

interface Props {
  steps: string[]
  currentStep: string
  isCoapplicant?: boolean
}

const StepProgressionBar = ({ steps, currentStep, isCoapplicant = false }: Props) => {
  const { t } = useTranslation()
  const stepIndex = steps.indexOf(currentStep) + 1
  return (
    <ul className="steps">
      {steps.includes('loan-amount') && (
        <li className="loan-amount">
          <a href="step_1.html">
            <i className="fa-light fa-circle-dollar" />
            <div className="step-info">
              <p>
                {t('common.step')} {stepIndex}
              </p>
              <h4>
                <span>
                  {t('common.step')} {stepIndex} -{' '}
                </span>
                {t('common.requestedAmount')}
              </h4>
            </div>
          </a>
        </li>
      )}

      {steps.includes('loan-purpose') && (
        <li className="loan-purpose">
          <a href="step_2.html">
            <i className="fa-light fa-hand-holding-dollar" />
            <div className="step-info">
              <p>
                {t('common.step')} {stepIndex}
              </p>
              <h4>
                <span>
                  {t('common.step')} {stepIndex} -{' '}
                </span>
                {t('common.loanPurpose')}
              </h4>
            </div>
          </a>
        </li>
      )}

      {steps.includes('service-provider') && (
        <li className="service-provider">
          <a href="step_3.html">
            <i className="fa-light fa-briefcase" />
            <div className="step-info">
              <p>
                {t('common.step')} {stepIndex}
              </p>
              <h4>
                <span>
                  {t('common.step')} {stepIndex} -{' '}
                </span>
                {t('common.serviceProvider')}
              </h4>
            </div>
          </a>
        </li>
      )}
      <li className="personal-information">
        <a href="step_4.html">
          <i className="fa-light fa-circle-user" />
          <div className="step-info">
            <p>
              {t('common.step')} {stepIndex}
            </p>
            <h4>
              <span>
                {t('common.step')} {stepIndex} -{' '}
              </span>
              {isCoapplicant ? t('common.aboutCoapplicant') : t('common.aboutYourself')}
            </h4>
          </div>
        </a>
      </li>
      <li className="contact">
        <a href="step_5.html">
          <i className="fa-light fa-id-card" />
          <div className="step-info">
            <p>
              {t('common.step')} {stepIndex}
            </p>
            <h4>
              <span>
                {t('common.step')} {stepIndex} -{' '}
              </span>
              {t('common.contactInfo')}
            </h4>
          </div>
        </a>
      </li>
      <li className="address">
        <a href="step_6.html">
          <i className="fa-light fa-map-location-dot" />
          <div className="step-info">
            <p>
              {t('common.step')} {stepIndex}
            </p>
            <h4>
              <span>
                {t('common.step')} {stepIndex} -{' '}
              </span>
              {t('common.yourHome')}
            </h4>
          </div>
        </a>
      </li>
      <li className="employment">
        <a href="step_7.html">
          <i className="fa-light fa-money-check-dollar-pen" />
          <div className="step-info">
            <p>
              {t('common.step')} {stepIndex}
            </p>
            <h4>
              <span>
                {t('common.step')} {stepIndex} -{' '}
              </span>
              {t('common.annualIncome')}
            </h4>
          </div>
        </a>
      </li>
      <li className="recap">
        <a href="step_8.html">
          <i className="fa-light fa-clipboard-list-check" />
          <div className="step-info">
            <p>
              {t('common.step')} {stepIndex}
            </p>
            <h4>
              <span>
                {t('common.step')} {stepIndex} -{' '}
              </span>
              {t('common.review')}
            </h4>
          </div>
        </a>
      </li>
    </ul>
  )
}

export default StepProgressionBar
