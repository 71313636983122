import { Trans, useTranslation } from 'react-i18next'
import { FormatCurrency } from '@src/services/Formatter'
import { InputText } from '@src/components'
import { useController, useFormContext } from 'react-hook-form'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { useCallback, useEffect, useState } from 'react'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { Merchant } from '@src/api/merchants-api'
import PromoCodeInput from '@src/components/PromoCodeInput'
import { Constants } from '@src/types'

interface Props {
  maxAmountFinanced: number
  setStepState: StepStateUpdater
  creditApp: FilteredCreditApplication
  codePromo: string | null
  setCodePromo: (codePromo: string | null) => void
  merchant: Merchant
}

const FinalAmountStep = ({ maxAmountFinanced, setStepState, creditApp, merchant, setCodePromo, codePromo }: Props) => {
  const { t } = useTranslation()
  const { control, setValue, getValues } = useFormContext<WorksheetForm>()
  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    name: 'amountRequested',
    control,
  })

  const [promoCodeIsValidating, setPromoCodeValidating] = useState<boolean>(false)
  const handleOnPaymentPlanIdChange = useCallback(
    (paymentPlanId: string | null, code: string | null) => {
      setValue('merchantPaymentPlanId', paymentPlanId, { shouldValidate: true, shouldDirty: true })
      setCodePromo(code)
    },
    [setCodePromo, setValue],
  )

  const canAddPromoCode = merchant.supportsPromotions && !creditApp.merchantPaymentPlanId

  useEffect(
    () => setStepState({ invalid, loading: promoCodeIsValidating }),
    [invalid, setStepState, promoCodeIsValidating],
  )

  useEffect(() => {
    if (field.value < Constants.LargeLoanAmount && getValues('term') === Constants.LongTerm)
      setValue('term', creditApp?.finalDecision?.maxTermDuration)
  }, [creditApp?.finalDecision?.maxTermDuration, field.value, getValues, setValue])

  return (
    <>
      <h3 style={{ marginBottom: '16px' }}>
        <i className="fa-solid fa-party-horn" />{' '}
        <Trans
          i18nKey="worksheet.finalAmount.title"
          values={{
            maxLoanAmount: FormatCurrency(maxAmountFinanced),
          }}
        />
      </h3>
      <h4 style={{ marginBottom: '16px' }}>{t('worksheet.finalAmount.confirmation')}</h4>
      <p style={{ marginBottom: '16px' }}>{t('worksheet.finalAmount.confirmation_subtext')}</p>
      <InputText error={error} label={t('worksheet.finalAmount.label')} id="amountRequested" type="number" {...field} />
      {canAddPromoCode && (
        <PromoCodeInput
          onChange={handleOnPaymentPlanIdChange}
          codePromo={codePromo}
          merchantId={merchant.id}
          setValidating={setPromoCodeValidating}
        />
      )}
    </>
  )
}

export default FinalAmountStep
