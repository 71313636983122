import { FilteredCreditApplication } from '@src/api/credit-api'
import { useRequiredDocumentsWithStatus } from '@src/data/credit-application-hooks'
import { EApplicantType, EDocumentStatus, EDocumentType } from '@src/types'
import { useTranslation } from 'react-i18next'
import RequiredDocumentTable from './RequiredDocumentTable'

interface Props {
  creditApp: FilteredCreditApplication
}
const UploadFilesTable = ({ creditApp }: Props): JSX.Element => {
  const reqDocList = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)
  const { t } = useTranslation()
  const applicantRequiredDocs = reqDocList.filter(
    (d) =>
      d.applicantType !== EApplicantType.CoApplicant &&
      d.typeId !== EDocumentType.SignedCVT &&
      d.status !== EDocumentStatus.Approved,
  )

  const coApplicantRequiredDocs = reqDocList.filter(
    (d) => d.applicantType === EApplicantType.CoApplicant && d.status !== EDocumentStatus.Approved,
  )

  return (
    <div className="paragraph" id="paragraph-document">
      <h4 className="subtitle">{t('common.documents')}</h4>
      {applicantRequiredDocs.length > 0 && (
        <RequiredDocumentTable
          applicant={`${creditApp.applicant.firstName} ${creditApp.applicant.lastName}`}
          requiredDocuments={applicantRequiredDocs}
          creditApplicationId={creditApp.id}
        />
      )}
      {creditApp.coapplicant && coApplicantRequiredDocs.length > 0 && (
        <RequiredDocumentTable
          applicant={`${creditApp.coapplicant.firstName} ${creditApp.coapplicant.lastName}`}
          requiredDocuments={coApplicantRequiredDocs}
          creditApplicationId={creditApp.id}
        />
      )}
    </div>
  )
}

export default UploadFilesTable
