import type { CreditApplicationDocument, FilteredApplicant, FilteredCreditApplication } from '@src/api/credit-api'
import {
  Constants,
  EApplicantType,
  EApplicationStep,
  EDocumentStatus,
  EDocumentType,
  EExternalStep,
  ELoanPurpose,
  EMPTY_ARRAY,
  ERequiredExternalStepStatus,
  EWorksheetStatus,
  RequiredDocument,
  RequiredDocumentWithStatus,
} from '../types'
import calculateMonthlyAmount from './compute-selectors'

export function mustAskApplicantSIN(filteredCreditApplication: FilteredCreditApplication) {
  return !filteredCreditApplication.applicant.hasSin && filteredCreditApplication.applicant.isCreditTwoYearsOrLess
}

export function mustAskCoapplicantSIN(filteredCreditApplication: FilteredCreditApplication) {
  return (
    filteredCreditApplication.coapplicant &&
    !filteredCreditApplication.coapplicant.hasSin &&
    filteredCreditApplication.coapplicant.isCreditTwoYearsOrLess
  )
}

export function getLoanAmount(creditApp: FilteredCreditApplication) {
  if (creditApp?.finalDecision) {
    let amount = Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced)

    if (creditApp.worksheet !== null) amount = Math.min(amount, creditApp.worksheet.amountRequested)
    return amount
  }
  return 0
}

export function getPrequalifiedAmount(creditApp: FilteredCreditApplication) {
  return Math.min(creditApp.requestedLoanAmount, creditApp.prequalificationDecision?.maxLoanAmount ?? Number.MAX_VALUE)
}

export function getMinPaymentForRequestedAmount(creditApplication: FilteredCreditApplication | null) {
  if (creditApplication?.prequalificationDecision) {
    const amount = Math.min(
      creditApplication?.requestedLoanAmount ?? Number.MAX_VALUE,
      creditApplication?.prequalificationDecision.maxLoanAmount ?? Number.MAX_VALUE,
    )
    const baseTerm = Math.min(
      creditApplication.applicant.age >= Constants.UpperThresholdAgeForApplicant
        ? Constants.CorrectedMaxTerm
        : Constants.BaseMaxTerm,
      creditApplication.requestedLoanAmount <= Constants.AmountThresholdForLowerTerm
        ? Constants.MaxTermUnderAmountThreshold
        : Constants.BaseMaxTerm,
    )
    return calculateMonthlyAmount(
      baseTerm,
      creditApplication?.prequalificationDecision.minInterestRate,
      amount,
      Constants.FinanceFeeRateForRegular,
    )
  }
  return 0
}

export function getMaxPaymentForRequestedAmount(creditApplication: FilteredCreditApplication | null) {
  if (creditApplication?.prequalificationDecision) {
    const amount = Math.min(
      creditApplication?.requestedLoanAmount ?? Number.MAX_VALUE,
      creditApplication?.prequalificationDecision.maxLoanAmount ?? Number.MAX_VALUE,
    )
    return calculateMonthlyAmount(
      creditApplication.applicant.age >= Constants.UpperThresholdAgeForApplicant
        ? Constants.CorrectedMaxTerm
        : Constants.BaseMaxTerm,
      creditApplication?.prequalificationDecision.maxInterestRate > 0
        ? creditApplication?.prequalificationDecision.maxInterestRate
        : Constants.MaxRate,
      amount,
      Constants.FinanceFeeRateForRegular,
    )
  }
  return 0
}

export function isDocumentMatchingRequiredDoc(document: CreditApplicationDocument, reqDoc: RequiredDocument) {
  return (
    document.typeId === reqDoc.typeId &&
    document.applicantType === reqDoc.applicantType &&
    document.subKey === reqDoc.subKey
  )
}

export function hasMissingDocuments(creditApp: FilteredCreditApplication, forStep?: EApplicationStep): boolean {
  const reqDocs = forStep
    ? creditApp.requiredDocuments.filter((x) => x.requiredBeforeStep === forStep)
    : creditApp.requiredDocuments

  return !reqDocs.every(
    (req) =>
      req.typeId === EDocumentType.SignedCVT ||
      req.typeId === EDocumentType.AuditCVT ||
      creditApp.documents.findIndex(
        (doc) => isDocumentMatchingRequiredDoc(doc, req) && !Constants.ExpectingDocumentStatuses.includes(doc.status),
      ) > -1,
  )
}

export const hasSignedContract = (creditApp: FilteredCreditApplication | null): boolean => {
  if (!creditApp) return false

  const idx = creditApp.documents.findIndex(
    (x) =>
      x.typeId === EDocumentType.SignedCVT &&
      (x.status === EDocumentStatus.AwaitingApproval || x.status === EDocumentStatus.Approved),
  )

  return idx > -1
}

export function areAllDocumentsApproved(creditApp: FilteredCreditApplication): boolean {
  return (
    creditApp.documents
      .filter((d) => d.typeId !== EDocumentType.SignedCVT && d.typeId !== EDocumentType.AuditCVT)
      .every((d) => d.status === EDocumentStatus.Approved) && !hasMissingDocuments(creditApp)
  )
}

const stepsBeforeOrEqualCVT = new Set<EApplicationStep>([
  EApplicationStep.Credit,
  EApplicationStep.Finaning,
  EApplicationStep.CVT,
])

export function areAllDocumentsReadyForCVT(requiredDocumentsWithStatus: RequiredDocumentWithStatus[]): boolean {
  return requiredDocumentsWithStatus.every(
    (d) => !stepsBeforeOrEqualCVT.has(d.requiredBeforeStep) || d.status === EDocumentStatus.Approved,
  )
}

export function hasApplicantSubmittedAllRequiredIncomeDocuments(
  reqDocWithStatus: RequiredDocumentWithStatus[],
  applicantType: EApplicantType,
): boolean {
  const applicantIncomeDocs = reqDocWithStatus.filter(
    (x) => x.applicantType === applicantType && Constants.IncomeProofTypes.includes(x.typeId),
  )
  if (applicantIncomeDocs.length === 0) return false

  const missing = applicantIncomeDocs.filter((x) => Constants.ExpectingDocumentStatuses.includes(x.status))

  return missing.length === 0
}

export function hasApplicantAskedToSkipBankRequest(
  creditApp: FilteredCreditApplication,
  applicantType: EApplicantType,
  skipBankAccountRequiredDocuments: RequiredDocument[],
): boolean {
  const applicantIncomeDocs = skipBankAccountRequiredDocuments.filter((x) => x.applicantType === applicantType)

  return (
    applicantIncomeDocs.length > 0 &&
    applicantIncomeDocs.every((r) => creditApp.requiredDocuments.findIndex((d) => d.id === r.id) > -1)
  )
}

export function hasApplicantSubmittedFlinks(creditApp: FilteredCreditApplication, applicantType: EApplicantType) {
  const inProgress = creditApp.requiredExternalSteps.some(
    (x) =>
      x.applicantType === applicantType &&
      x.externalStepId === EExternalStep.BankAccount &&
      (x.status === ERequiredExternalStepStatus.WaitingForBank ||
        x.status === ERequiredExternalStepStatus.AnalysisInProgress),
  )

  return inProgress
}

export function hasBankSatementsUnderReviewOrWaitingForBank(
  creditApp: FilteredCreditApplication | null,
  applicantType: EApplicantType,
) {
  if (creditApp) {
    const applicant = applicantType === EApplicantType.Applicant ? creditApp.applicant : creditApp.coapplicant

    const bankStatementUnderReview =
      creditApp.documents.some(
        (e) =>
          (e.typeId === EDocumentType.SixMonthsBankStatements ||
            e.typeId === EDocumentType.ThreeMonthsPersonalBankStatements ||
            e.typeId === EDocumentType.LessThanThreeMonthsPersonalBankStatements) &&
          e.status === EDocumentStatus.AwaitingApproval &&
          e.applicantType === applicantType,
      ) &&
      (applicant?.areFlinksIncomeMatchingDeclared ?? false)

    return hasApplicantSubmittedFlinks(creditApp, applicantType) || bankStatementUnderReview
  }

  return false
}

export function hasApplicantCompletedFlinks(
  creditApp: FilteredCreditApplication | null,
  applicantType: EApplicantType,
) {
  if (creditApp) {
    const reqStep = creditApp.requiredExternalSteps.find(
      (x) =>
        x.applicantType === applicantType &&
        x.externalStepId === EExternalStep.BankAccount &&
        x.status === ERequiredExternalStepStatus.Completed,
    )

    if (reqStep) {
      return true
    }
  }

  return false
}

export function areApplicantIncomesAreUnderReview(
  creditApp: FilteredCreditApplication,
  applicantType: EApplicantType,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  const areIncomeDocsSubmitted = hasApplicantSubmittedAllRequiredIncomeDocuments(
    skipBankAccountRequiredDocuments,
    applicantType,
  )

  const applicantBankSatementsUnderReviewOrWaitingForBank = hasBankSatementsUnderReviewOrWaitingForBank(
    creditApp,
    applicantType,
  )

  const areIncomesConfirmed =
    applicantType === EApplicantType.Applicant
      ? creditApp.applicant.areIncomesConfirmed
      : creditApp.coapplicant?.areIncomesConfirmed

  return !areIncomesConfirmed && (areIncomeDocsSubmitted || applicantBankSatementsUnderReviewOrWaitingForBank)
}

export function canCompleteWorksheet(creditApp: FilteredCreditApplication): boolean {
  return creditApp.loanPurposeId === ELoanPurpose.GoodsAndServices
}

export function isWorksheetCompleted(creditApp: FilteredCreditApplication): boolean {
  return creditApp.worksheet?.status === EWorksheetStatus.Active
}

export function applicantFullName(applicant: FilteredApplicant): string {
  return `${applicant.firstName}\u00a0${applicant.lastName}`
}

export function applicantFlinksId(creditApp: FilteredCreditApplication | null, applicantType: EApplicantType) {
  if (creditApp) {
    const reqStep = creditApp.requiredExternalSteps.find(
      (x) => x.applicantType === applicantType && x.externalStepId === EExternalStep.BankAccount,
    )

    if (reqStep) {
      return reqStep.externalServiceId
    }
  }

  return ''
}

export function buildRequiredDocumentsWithStatus(
  requiredDocuments: RequiredDocument[] | undefined | null,
  documents: CreditApplicationDocument[] | undefined | null,
) {
  if (!requiredDocuments || !documents) return EMPTY_ARRAY

  const ret = [] as RequiredDocumentWithStatus[]
  requiredDocuments.forEach((req) => {
    const doc = documents.find((d) => isDocumentMatchingRequiredDoc(d, req))
    if (doc) ret.push({ ...req, status: doc.status, refusalReason: doc.refusalReason } as RequiredDocumentWithStatus)
    else ret.push({ ...req, status: EDocumentStatus.AwaitingDocument, refusalReason: null })
  })

  return ret
}
export function hasFlexPlan(creditApp: FilteredCreditApplication) {
  return creditApp.merchantPaymentPlan?.loanTerm !== creditApp.merchantPaymentPlan?.reducedRateDurationInMonths
}
