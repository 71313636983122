import { IFINANCE_ID, isIFinanceMerchant, isMedicardMerchant, MEDICARD_ID } from '@src/data/merchant-selectors'
import { isMedicardWebSite } from '@src/services/query-string'
import React, { ReactEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentMerchantId, useInitializePageTitleAndIcon } from './header-hooks'

const IFINANCE_WEBSITE_HOST = 'https://ifinancecanada.com'
const MEDICARD_WEBSITE_HOST = 'https://medicard.com'

interface HeaderProps {
  language: string
}

const Header = ({ language }: HeaderProps) => {
  const { t, i18n } = useTranslation()
  const [displayDefaultLogo, setDisplayDefaultLogo] = React.useState(false)
  const [logoClasses, setLogoClasses] = React.useState('')
  const targetLanguage = t('common.targetLanguage')

  const isMedicardHost = isMedicardWebSite()

  const currentMerchantId = useCurrentMerchantId()
  useInitializePageTitleAndIcon()

  const merchantIsMedicard = isMedicardMerchant(currentMerchantId)
  const merchantIsIfinance = isIFinanceMerchant(currentMerchantId)

  const logoName = `${merchantIsMedicard ? `${currentMerchantId}_${language}` : currentMerchantId}.png`

  const needToHelpUrl =
    merchantIsMedicard || isMedicardHost
      ? `${MEDICARD_WEBSITE_HOST}/contact-us.php?lang=${language}`
      : `${IFINANCE_WEBSITE_HOST}/${language}/contact/`

  const backToUrl = () => {
    if (merchantIsMedicard || isMedicardHost) return `${MEDICARD_WEBSITE_HOST}?lang=${language}`
    return language === 'fr' ? `${IFINANCE_WEBSITE_HOST}/${language}/` : IFINANCE_WEBSITE_HOST
  }

  const defaultLogoIfNotFound = () => {
    if (isMedicardHost) return `${MEDICARD_ID}_${language}`
    return `${IFINANCE_ID}`
  }

  const handleLogoAspectRatio: ReactEventHandler<HTMLImageElement> = (e) => {
    const { naturalWidth, naturalHeight } = e.target as HTMLImageElement
    const ratio = naturalWidth / naturalHeight
    if (ratio > 1.5) {
      setLogoClasses('wide')
    } else if (ratio < 0.75) {
      setLogoClasses('tall')
    } else {
      setLogoClasses('')
    }
  }

  return (
    <header className="header" style={{ background: 'white' }}>
      <div className="wrap">
        <figure className="logo">
          <a href={merchantIsIfinance || merchantIsMedicard ? backToUrl() : undefined} target="_blank" rel="noreferrer">
            <img
              id="logo-img"
              className={logoClasses}
              src={`${import.meta.env.VITE_BLOB_STORAGE_URL}/${logoName}`}
              onLoad={handleLogoAspectRatio}
              onError={(e) => {
                setDisplayDefaultLogo(true)
                e.currentTarget.onerror = null
                e.currentTarget.src = `${import.meta.env.VITE_BLOB_STORAGE_URL}/${defaultLogoIfNotFound()}.png`
              }}
              alt="iFinance Logo"
            />
          </a>
          {!merchantIsMedicard && !merchantIsIfinance && !displayDefaultLogo && (
            <img className="partnership" src={`/images/in_partnership_${language}.svg`} alt="in partnership iFinance" />
          )}
        </figure>
        <div className="right">
          <a href={needToHelpUrl} target="_blank" className="btn-simple" rel="noreferrer">
            {t('common.needHelp')}
          </a>
          <a href={backToUrl()} className="btn-simple">
            {t('common.backTo', { name: merchantIsMedicard || isMedicardHost ? 'Medicard' : 'iFinance' })}
          </a>
          <button type="button" className="btn btn-blue" onClick={() => i18n.changeLanguage(targetLanguage)}>
            {targetLanguage}
          </button>
        </div>
      </div>
    </header>
  )
}

export default React.memo(Header)
