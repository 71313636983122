import { useGetProvincesConfigs } from '@src/api/config-api'
import { UpdatePrequalificationDto, useUpdatePrequalification } from '@src/api/credit-api'
import { Merchant, useMerchantById } from '@src/api/merchants-api'
import { Loader } from '@src/components'
import { getRecaptchaToken } from '@src/services/recaptcha'
import { scrollToTop } from '@src/services/utils'
import { ESessionStorageKeys } from '@src/types'
import { t } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate, useParams } from 'react-router-dom'
import AddressForm from './components/AddressForm'
import PersonalInformationForm from './components/PersonalInformationForm'
import { Applicant, PersonalInformationInput, Prequalification } from './components/PrequalificationSchema'
import StepperFormFooter from './components/StepperFormFooter'
import { buildDefaultPrequalification, useSessionStorage } from './submit-app-hooks'

const UpdatePersonalInformationForm = () => {
  const urlParams = useParams()
  const currentStep = urlParams.currentStep!
  const urlMerchantId = urlParams.merchantId!
  const [merchant, isFetchingMerchant] = useMerchantById(urlMerchantId)
  const [provinceConfigs, isLoadingConfig] = useGetProvincesConfigs()
  const [prequalInfo, setPrequalInfo] = useSessionStorage<Prequalification>(
    ESessionStorageKeys.PREQUAL_INFO,
    merchant ? buildDefaultPrequalification(merchant) : buildDefaultPrequalification({ id: urlMerchantId } as Merchant),
  )
  const [updatePrequal, isUpdating] = useUpdatePrequalification()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useNavigate()
  const [disableNext, setDisableNext] = useState(false)

  const steps = useMemo(() => ['personal-information', 'address'], [])

  const cls = `form-section step-${currentStep}-active`

  const gotoStep = useCallback((stepPath: string) => {
    scrollToTop()
    navigate(`/${urlMerchantId}/creditapplication/${urlParams.id}/editPersonalInformation/${stepPath}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nextStepAfter = useCallback(() => {
    const nextStepIdx = steps.indexOf(currentStep.toLowerCase()) + 1
    if (nextStepIdx < steps.length) {
      gotoStep(steps[nextStepIdx])
    }
  }, [currentStep, gotoStep, steps])

  const handlePrequalificationUpdated = (data: PersonalInformationInput) => {
    const { applicant, ...otherData } = data
    const updatedPrequalInfo = {
      ...prequalInfo,
      ...otherData,
      applicant: { ...prequalInfo.applicant, ...applicant },
    } as Prequalification
    setPrequalInfo(updatedPrequalInfo)
    nextStepAfter()
  }

  const handleApplicantUpdated = async (data: Partial<Applicant>) => {
    const update = { ...prequalInfo, applicant: { ...prequalInfo.applicant, ...data } }
    setPrequalInfo(update)

    const recaptchaToken = await getRecaptchaToken(executeRecaptcha, 'submit_personalInformation')
    const dto: UpdatePrequalificationDto = {
      creditApplicationId: urlParams.id!,
      personalInfoDto: {
        ...update,
      },
      recaptchaToken,
    }
    await updatePrequal(dto)
    navigate(`/creditapplication/${urlParams.id}`, { replace: true })
  }

  if (!urlMerchantId || isLoadingConfig || isFetchingMerchant || isUpdating || !merchant)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return (
    <main className={cls}>
      <h1 className="heading-primary">{t('identity.updateInfo')}</h1>

      {currentStep === 'personal-information' && (
        <PersonalInformationForm
          prequalificationData={prequalInfo}
          onPrequalificationUpdated={handlePrequalificationUpdated}
          loanPurposeId={prequalInfo.loanPurposeId}
          provinceConfigs={provinceConfigs}
        />
      )}

      {currentStep === 'address' && (
        <AddressForm
          applicantData={prequalInfo.applicant}
          onApplicantUpdated={handleApplicantUpdated}
          applicantName={prequalInfo.applicant.firstName}
          setDisableNext={setDisableNext}
          provinceConfigs={provinceConfigs}
        />
      )}
      <StepperFormFooter
        currentStepIndex={steps.indexOf(currentStep) + 1}
        stepLength={steps.length}
        disableBack={false}
        disableNext={disableNext}
      />
    </main>
  )
}

export default UpdatePersonalInformationForm
