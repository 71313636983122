import { MutationFunction, QueryFunctionContext, useMutation, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'refinance'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

export interface ApplicationRefinancingParams {
  id: string
  createOn: string
  referenceNumber: number
  applicantFirstName: string
  applicantLastName: string
  aplicantMiddleName: string
  availableAmount: number
  expiresOn: Date
  canAddInsurance: boolean
}

const getRefinancingParams = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await apiClient.get<ApplicationRefinancingParams>(
    `/api/creditApplication/${encodeURIComponent(id)}/refinancing`,
  )
  return response.data
}

export function useGetRefinancingParams(creditApplicationId: string): [ApplicationRefinancingParams | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getRefinancingParams,
    enabled: !!creditApplicationId,
  })

  return [data ?? null, isFetching]
}

export interface SubmitRefinancingDto {
  id: string
  requestedLoanAmount: number
  requestedPaymentOn: Date
  includeInsurance: boolean
}

export interface SubmitRefinancingResultDto {
  id: string
  createOn: string
  referenceNumber: number
  requestedLoanAmount: number
  requestedPaymentOn: Date
  applicantFirstName: string
  applicantLastName: string
  aplicantMiddleName: string
  availableAmount: number
  expiresOn: Date
  canAddInsurance: boolean
  includeInsurance: boolean
}
const submitRefinancing: MutationFunction<SubmitRefinancingResultDto, SubmitRefinancingDto> = async (
  dto: SubmitRefinancingDto,
) => {
  const response = await apiClient.post(`api/creditApplication/${dto.id}/refinancing`, dto)

  return response.data as SubmitRefinancingResultDto
}

export function useSubmitRefinancing(): [
  MutationFunction<SubmitRefinancingResultDto, SubmitRefinancingDto>,
  boolean,
  () => void,
] {
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: submitRefinancing,
  })

  return [mutateAsync, isPending, reset]
}
