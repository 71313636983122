import { useCompleteApplicantFlinks, useGetCreditApplicationById, EOriginSystemId } from '@src/api/credit-api'
import { Loader } from '@src/components'
import { applicantFlinksId } from '@src/data/credit-application-selectors'
import getRuntimeVariable from '@src/services/env'
import { reportErrorToConsole } from '@src/services/error-logger'
import { EApplicantType, EFlinksEventSteps } from '@src/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const FlinksIncomeValidationPage = (): JSX.Element => {
  const { id, applicantType } = useParams<{ id: string; applicantType: EApplicantType }>()
  const { t, i18n } = useTranslation()
  const [completeFlinks, isCompletingFlinks] = useCompleteApplicantFlinks()
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id!, false)
  const [isInProgress, setIsInProgress] = useState(true)
  const externalServiceId = applicantFlinksId(creditApp, applicantType!)
  const navigate = useNavigate()
  const originSystemId = creditApp?.originSystemId

  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent<{ step?: EFlinksEventSteps }>) => {
      if (e.data?.step) {
        // The request is done, we are receiving the answer
        if (e.data.step === EFlinksEventSteps.REDIRECT) {
          setIsInProgress(false)
          const completeFlinksDto = {
            id: id!,
            externalServiceId,
            applicantType: applicantType!,
          }

          completeFlinks(completeFlinksDto).catch(reportErrorToConsole)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoadingApp || isCompletingFlinks)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  const iframeUrl =
    import.meta.env.VITE_FLINKS_IFRAME +
    `?theme=light
  &desktopLayout=true
  &customerName=iFinance
  &institutionFilterEnable=true
  &demo=${getRuntimeVariable<boolean>(import.meta.env.VITE_USE_DEMO_FLINKS) ? 'true' : 'false'}
  &consentEnable=true
  &accountSelectorEnable=true
  &daysOfTransactions=Days365
  &monthsOfStatements=Months12
  &enhancedMFA=true
  &staticLoadingEnable=true
  &tag=${import.meta.env.VITE_ENV}_${externalServiceId}
  &language=${i18n.language}
  &scheduleRefresh=false`
      .replace(/ /g, '')
      .replace(/(\r\n|\n|\r)/gm, '')

  if (isInProgress)
    return <iframe title="flinksIFrame" width="100%" loading="eager" src={iframeUrl} style={{ flexGrow: 1 }} />

  return (
    <main id="flinks-validation" className="general-message">
      <h1 style={{ marginBottom: '1rem' }}>{t('common.thankYou')}</h1>
      <h3>{t('flinks.accountRetrieved')}</h3>
      <h3>{t('flinks.processingInfomation')}</h3>
      <div className="btn-group full-width-mobile" style={{ marginTop: '16px' }}>
        {originSystemId !== EOriginSystemId.MerchantDashboard && (
          <button
            type="button"
            className="btn btn-blue"
            style={{ gap: '2rem', minWidth: '0', justifyContent: 'center' }}
            onClick={() => navigate(`/creditapplication/${id}`)}
          >
            <i className="fa-regular fa-arrow-left" />
            <span>{t('flinks.back')}</span>
          </button>
        )}
      </div>
    </main>
  )
}

export default FlinksIncomeValidationPage
