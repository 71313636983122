import { useRemoveCoapplicant } from '@src/api/credit-api'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'

interface Props {
  creditAppId: string
}

const RemoveCoappButton = ({ creditAppId }: Props) => {
  const { t } = useTranslation()
  const [submitRemoveCoapp, isRemovingCoapp] = useRemoveCoapplicant()

  const onClick = () => submitRemoveCoapp(creditAppId)

  return (
    <AsyncActionButton
      type="button"
      withConfirm
      onClick={onClick}
      isPending={isRemovingCoapp}
      className="btn secondary-action-btn"
    >
      <i className="fa-regular fa-user-minus" />
      <span>{t('common.removeCoapplicant')}</span>
    </AsyncActionButton>
  )
}

export default RemoveCoappButton
