import { useTranslation } from 'react-i18next'

interface Props {
  title?: string
  description?: string
}

const ErrorPage = ({ title, description }: Props) => {
  const { t } = useTranslation()
  return (
    <main className="step-0">
      <h4 className="subtitle">{title ?? t('common.notFound')}</h4>
      <h1>{t('common.oopsTitle')}</h1>
      <div className="paragraph">{description ?? t('common.unexpectedError')}</div>
    </main>
  )
}

export default ErrorPage
