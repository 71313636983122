import { createContext, useContext, useEffect } from 'react'

export interface StepperStepOptions {
  step: string
  invalid: boolean
  loading: boolean
  hideControl: boolean
  disabled: boolean
  icon: string
  label: string
}

export type Noop = () => void

export interface StepperContextProps {
  current: string | null
  setCurrentStep: (step: string) => void
  currentStep?: StepperStepOptions
  currentPosition?: number
  steps: StepperStepOptions[]
  onSubmit?: () => void
  isSubmitting: boolean
  setStep: (options: StepperStepOptions) => void
  setNext?: Noop
  setPrevious?: Noop
}

export const StepperContext = createContext<StepperContextProps | null>(null)

export const useStepper = () => {
  return useContext(StepperContext)!
}

export const useStep = ({ step, icon, label, invalid, loading, hideControl, disabled }: StepperStepOptions) => {
  const { setStep, current, setNext, setPrevious, isSubmitting } = useContext(StepperContext)!

  useEffect(() => {
    setStep({
      step,
      icon,
      label,
      invalid,
      hideControl,
      loading,
      disabled,
    })
    // Eslint disabled pour optimisation et boucle infini. Faire attention à ajouter manuellement les properties nécessaires
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalid, label, loading, hideControl, disabled])

  return { current, setPrevious, setNext, isSubmitting }
}
