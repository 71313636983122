import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface Props {
  creditAppId: string
  useChangeLabel?: boolean
}

const ManageCoappButton = ({ creditAppId, useChangeLabel = false }: Props) => {
  const { t } = useTranslation()

  return (
    <Link
      className="btn secondary-action-btn btn-simple"
      to={`/creditapplication/${creditAppId}/addcoapplicant/personal-information`}
    >
      {useChangeLabel ? t('common.changeCoapplicant') : t('common.addCoapplicant')}
    </Link>
  )
}

export default ManageCoappButton
