import { t, TOptionsBase } from 'i18next'
import { $Dictionary } from 'node_modules/i18next/typescript/helpers'

/**
 * @deprecated for comaptibility reason only. use hook or import "t" directly from i18n pour les messages éphémère (no refresh on lang change)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const translate = (key: any, params?: TOptionsBase & $Dictionary): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unnecessary-type-assertion
  return t(key, params) as string
}

export default translate
