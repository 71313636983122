import { useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import { getStringFromQueryString } from '@src/services/query-string'
import { EApplicantType } from '@src/types'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { contractIsReceived } from './ViewCreditApplication/HardHitApprovedResultPage/hooks'

const ConsignoCallBack = () => {
  const [searchParams] = useSearchParams()
  const { id, applicantType } = useParams<{ id: string; applicantType: EApplicantType }>()
  const navigate = useNavigate()
  const [shouldPollCreditApp, setShouldPollCreditApp] = useState(true)
  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(id!, shouldPollCreditApp)
  const status = getStringFromQueryString(searchParams, 'status')

  const contractReceived = contractIsReceived(applicantType ?? null, creditApp)

  useEffect(() => {
    if (!isFetchingCreditApp && !contractReceived) {
      setShouldPollCreditApp(true)
    }

    if (contractReceived) {
      setShouldPollCreditApp(false)
    }
  }, [isFetchingCreditApp, contractReceived, shouldPollCreditApp])

  useEffect(() => {
    if (!creditApp && !isFetchingCreditApp) navigate('', { replace: true })
    else if (creditApp && !contractReceived && !status && !isFetchingCreditApp)
      navigate(`/creditapplication/${id}`, { replace: true })
  }, [contractReceived, creditApp, id, isFetchingCreditApp, navigate, status])

  if (contractReceived || (!applicantType && status))
    return (
      <main id="flinks-validation" className="general-message">
        <div style={{ alignSelf: 'center', textAlign: 'center' }}>
          <h1 style={{ marginBottom: '1rem' }}>{t('common.thankYou')}</h1>

          {status === 'decline' ? (
            <h3>{t('contract.refused')}</h3>
          ) : (
            <>
              <h3>{t('contract.received')}</h3>
              <h3>{t('contract.review')}</h3>
            </>
          )}

          <div>
            <Link
              style={{ gap: '2rem', minWidth: '0', justifyContent: 'center' }}
              to={`/creditapplication/${id}`}
              className="btn btn-blue"
            >
              <i className="fa-regular fa-arrow-left" />
              <span>{t('flinks.back')}</span>
            </Link>
          </div>
        </div>
      </main>
    )
  return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
}

export default ConsignoCallBack
