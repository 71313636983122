import { replaceLastSpaceWithNbsp } from '@src/services/utils'
import { CustomError, EDocumentStatus, RequiredDocumentWithStatus } from '@src/types'
import i18n from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UploadFileButton from './UploadFileButton'

interface Props {
  creditApplicationId: string
  applicant: string
  requiredDocuments: RequiredDocumentWithStatus[]
}

const RequiredDocumentTable = ({ requiredDocuments, applicant, creditApplicationId }: Props) => {
  const { t } = useTranslation()
  const [error, setError] = useState<CustomError>()

  const getStatusClass = (status: EDocumentStatus) => {
    const statusClassMap = {
      [EDocumentStatus.AwaitingDocument]: 'text-statusBlue',
      [EDocumentStatus.AwaitingApproval]: 'text-statusYellow',
      [EDocumentStatus.Incomplete]: 'text-statusYellow',
      [EDocumentStatus.Refused]: 'text-statusRed',
      [EDocumentStatus.Approved]: 'text-statusGreen',
    }

    return statusClassMap[status] ?? ''
  }

  const hasRefusedDocument = requiredDocuments.some((doc) => doc.status === EDocumentStatus.Refused)

  const hasIncompletedDocument = requiredDocuments.some((doc) => doc.status === EDocumentStatus.Incomplete)

  return (
    <div className="scroll-container">
      <h3> {applicant && `${applicant.charAt(0).toUpperCase()}${applicant.slice(1)}`}</h3>
      <table className="styled-table">
        <thead>
          <tr>
            <th>{t('uploadRequiredFile.documentName')}</th>
            <th>{t('uploadRequiredFile.status')}</th>
            <th>{t('uploadRequiredFile.upload')}</th>
          </tr>
        </thead>
        <tbody>
          {requiredDocuments.map((doc) => (
            <tr key={doc.id}>
              <td data-label={t('common.document')}>
                {i18n.language === 'fr' ? replaceLastSpaceWithNbsp(doc.nameFr) : replaceLastSpaceWithNbsp(doc.nameEn)}

                <br />
                <span className="table-note">{doc.note ?? ''}</span>
              </td>
              <td className="fixed-width-250" data-label={t('uploadRequiredFile.status')}>
                {error && error.id === doc.id ? (
                  <span className={getStatusClass(EDocumentStatus.Refused)}>
                    {t(`enum.eDocumentStatus.${EDocumentStatus.Refused}`)}: {error.message}
                  </span>
                ) : (
                  <span className={getStatusClass(doc.status)}>{t(`enum.eDocumentStatus.${doc.status}`)}</span>
                )}
                {(hasRefusedDocument || hasIncompletedDocument) &&
                  (window.innerWidth > 600 ? (
                    <>
                      <br />
                      <span
                        className="table-note"
                        style={{ color: '#E97472', textAlign: 'justify', paddingRight: '10px' }}
                      >
                        {(doc.status === EDocumentStatus.Refused || doc.status === EDocumentStatus.Incomplete) &&
                          doc.refusalReason}
                      </span>
                    </>
                  ) : (
                    doc.refusalReason !== null && (
                      <>
                        <br />
                        <span
                          className="table-note"
                          style={{
                            color: '#E97472',
                            textAlign: 'justify',
                          }}
                        >
                          {(doc.status === EDocumentStatus.Refused || doc.status === EDocumentStatus.Incomplete) &&
                            doc.refusalReason}
                        </span>
                      </>
                    )
                  ))}
              </td>
              <td data-label={t('uploadRequiredFile.upload')}>
                <UploadFileButton
                  id={doc.id}
                  creditApplicationId={creditApplicationId}
                  typeId={doc.typeId}
                  subKey={doc.subKey}
                  applicantType={doc.applicantType}
                  disabled={doc.status === EDocumentStatus.Approved}
                  setError={setError}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default RequiredDocumentTable
