const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days)
  return date
}

export default addDays

export const getCurrentDateEDT = (): Date => {
  return new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Toronto' }))
}
