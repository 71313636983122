import { useValidatePromoCode } from '@src/api/merchants-api'
import { usePromoCodeYupValidator } from '@src/containers/Worsheet/schema-hooks'
import { EPromoCodeStatus } from '@src/types'
import { KeyboardEventHandler, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InferType, ValidationError } from 'yup'

interface Props {
  onChange: (paymentPlanId: string | null, codePromo: string | null) => void
  merchantId: string
  setValidating?: (v: boolean) => void
  setError?: (e: string | null) => void
  codePromo?: string | null
}

const PromoCodeInput = ({
  onChange,
  setValidating: pSetValidating,
  merchantId,
  codePromo = null,
  setError: pSetError,
}: Props) => {
  const { t } = useTranslation()
  const [validatePromoCodeQuery] = useValidatePromoCode()

  const [error, setErrorState] = useState<string | null>('')
  const [validating, setValidatingState] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(codePromo ?? '')
  const [validCodePromo, setValidCodePromo] = useState<string | null>(codePromo)
  const promoCodeValidator = usePromoCodeYupValidator()

  const setError = useCallback(
    (e: string | null) => {
      setErrorState(e)
      if (pSetError) pSetError(e)
    },
    [pSetError],
  )
  const setValidating = useCallback(
    (e: boolean) => {
      setValidatingState(e)
      if (pSetValidating) pSetValidating(e)
    },
    [pSetValidating],
  )

  const setPromoCode = useCallback(
    (v: string | null) => {
      if (!v) return
      setValidating(true)
      promoCodeValidator
        .validate(v)
        .then((data: InferType<typeof promoCodeValidator>) => {
          if (!data) return null
          return validatePromoCodeQuery({ merchantId, promotionCode: data })
        })
        .then((data) => {
          setValidating(false)
          if (data?.status === EPromoCodeStatus.Valid) {
            setError(null)
            setValidCodePromo(v)
          } else {
            setValidCodePromo(null)
            if (data?.status === EPromoCodeStatus.Expired) setError(t('loanAmount.expiredPromo'))
            else if (data?.status === EPromoCodeStatus.Invalid) setError(t('loanAmount.invalidPromo'))
          }
          onChange(data?.merchantPaymentPlanId ?? null, data?.merchantPaymentPlanId ? v : null)
        })
        .catch((e: ValidationError) => {
          setError(e.message)
          setValidating(false)
          setValidCodePromo(null)
          onChange(null, null)
        })
    },
    [merchantId, onChange, promoCodeValidator, setError, setValidating, t, validatePromoCodeQuery],
  )

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      setPromoCode(inputValue)
    }
  }

  const hasValidCode = Boolean(validCodePromo) && !validating
  return (
    <div className="review-wrap" style={{ padding: 0, margin: 0, border: 0, marginTop: '32px' }}>
      <div className="review-item">
        <h1 className="h3">{t('loanAmount.promoCode')}</h1>
        <div className="box" style={{ padding: '2.5rem 3rem 2.5rem 3rem' }}>
          <div
            className="box-content"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <div className="control-group mid">
              <label htmlFor="promoCode">{t('loanAmount.promoCode')}</label>
              <input
                style={{ textTransform: 'uppercase' }}
                id="promoCode"
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.currentTarget.value?.toUpperCase())}
                onKeyDown={handleKeyDown}
                disabled={validating}
              />
            </div>
            <div className="control-group mid">
              <button
                disabled={validating || !inputValue || inputValue === validCodePromo}
                style={{ margin: 0 }}
                type="button"
                className="btn btn-blue"
                onClick={() => setPromoCode(inputValue)}
              >
                {validating ? (
                  <>
                    {t('loanAmount.validatingPromo')}
                    <div className="mini-spinner" />
                  </>
                ) : (
                  t('loanAmount.validatePromo')
                )}
              </button>
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          {hasValidCode && (
            <div className="text-success" style={{ marginBottom: '8px' }}>
              {t('loanAmount.validPromo')}&nbsp;
              <i className="fa-regular fa-check" />
            </div>
          )}
          {hasValidCode && (
            <p>
              <strong>{t('loanAmount.validPromo')} : </strong>
              <span>{validCodePromo?.toUpperCase()}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default PromoCodeInput
