import { yupResolver } from '@hookform/resolvers/yup'
import { SearchMerchantDto, useSearchMerchant } from '@src/api/merchants-api'
import { InputText } from '@src/components'
import { useCurrentMerchantId } from '@src/containers/App/components/header-hooks'
import {
  IFINANCE_ID,
  MEDICARD_ID,
  isDefaultMerchant,
  isIFinanceMerchant,
  isMedicardMerchant,
} from '@src/data/merchant-selectors'
import { removeDiacritics } from '@src/services/Formatter'
import { ELoanPurpose } from '@src/types/constants'
import { debounce } from 'lodash-es'
import { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { ServiceProviderInput, ServiceProviderSchema } from './PrequalificationSchema'
import { FORM_ID } from './StepperFormFooter'

interface Props {
  onPrequalificationUpdated: (data: ServiceProviderInput) => void
  prequalificationData: ServiceProviderInput
  loanPurposeId: ELoanPurpose
}

const EXCLUDED_WORDS = [
  '.',
  ',',
  ':',
  'le',
  'la',
  'les',
  'de',
  'du',
  'des',
  'au',
  'aux',
  'dans',
  'par',
  'pour',
  'sur',
  'the',
  'one',
  'and',
  'on',
  'of',
  'a',
  'dr.',
  'dr',
]

const ServiceProviderForm = ({ onPrequalificationUpdated, prequalificationData, loanPurposeId }: Props) => {
  const { t } = useTranslation()
  const MERCHANTS_DROP_DOWN_LIMIT = 10
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch,
  } = useForm<ServiceProviderInput>({
    mode: 'onBlur',
    resolver: yupResolver(ServiceProviderSchema),
    defaultValues: prequalificationData,
  })

  const merchantName = watch('merchantName')
  const selectedMerchantId = watch('merchantId')
  const hasSelectedMerchant = !isDefaultMerchant(selectedMerchantId)
  const currentMerchantId = useCurrentMerchantId()
  const merchantIsMedicard = isMedicardMerchant(currentMerchantId)

  const [searchMerchantQuery, setSearchMerchantQuery] = useState<SearchMerchantDto>({
    financingProgramId: 'personal',
    serviceCategory: loanPurposeId,
    nameContains: prequalificationData.merchantId ? '' : prequalificationData.merchantName,
  })
  const defaultMerchantId = loanPurposeId === ELoanPurpose.Medical ? MEDICARD_ID : IFINANCE_ID

  const [merchants, isLoadingMerchants] = useSearchMerchant(searchMerchantQuery)

  const getRelevantWordsFromQuery = useCallback((queryString: string) => {
    const words = queryString.split(' ')
    return words.filter((word) => !EXCLUDED_WORDS.includes(word))
  }, [])

  const setSearchName = useCallback(
    (name: string) => {
      setSearchMerchantQuery((prev: SearchMerchantDto) => {
        return { ...prev, nameContains: name }
      })
    },
    [setSearchMerchantQuery],
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deboucedUpdateSearchName = useCallback(debounce(setSearchName, 1000), [setSearchName])
  const onMerchantNameChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue('merchantName', e.target.value)
      setValue('merchantId', defaultMerchantId)
      setValue('loanPurposeId', loanPurposeId)
      deboucedUpdateSearchName(e.target.value)
    },
    [deboucedUpdateSearchName, defaultMerchantId, loanPurposeId, setValue],
  )
  const skipMerchantSelection = useCallback(() => {
    const form = getValues()
    if (merchantIsMedicard) {
      form.loanPurposeId = ELoanPurpose.Medical
    }
    onPrequalificationUpdated(form)
  }, [getValues, merchantIsMedicard, onPrequalificationUpdated])

  const bottomRef = useRef<HTMLDivElement | null>(null)

  const isIfinanceMerchant = isIFinanceMerchant(defaultMerchantId)

  const loanPurposeClinic = [
    ELoanPurpose.Medical,
    ELoanPurpose.Aesthetics,
    ELoanPurpose.Dentistry,
    ELoanPurpose.Veterinary,
  ]

  const requiresSpecialistName = loanPurposeClinic.includes(loanPurposeId)

  const handleMerchantSelectedFromList = useCallback(
    (id: string) => () => {
      const clickedMerchant = merchants.find((m) => m.id === id)
      if (clickedMerchant) {
        setValue('merchantName', clickedMerchant.name)
        setValue('merchantId', clickedMerchant.id)
        setValue('loanPurposeId', clickedMerchant.serviceCategory)
      }
    },
    [merchants, setValue],
  )

  return (
    <section className="step-content">
      <h3 className="form-question">{t('serviceProvider.provider')}</h3>

      {isIfinanceMerchant && (
        <div className="paragraph">
          <p>{t('serviceProvider.providerSelection')}</p>
        </div>
      )}

      <form onSubmit={handleSubmit(onPrequalificationUpdated)} id={FORM_ID}>
        <div className={`control-group ${requiresSpecialistName && 'mid'} upper ${errors.merchantName && 'error'}`}>
          <label htmlFor="name">
            {requiresSpecialistName ? t('serviceProvider.providerName') : t('serviceProvider.merchantName')}
          </label>
          <div className="input-with-icon">
            {selectedMerchantId && selectedMerchantId !== defaultMerchantId && <i className="fa fa-check icon" />}
            {isLoadingMerchants && <i className="fa fa-spinner fa-spin icon" style={{ top: '35%' }} />}
            <input
              type="text"
              id="merchantName"
              maxLength={100}
              onChange={onMerchantNameChanged}
              value={merchantName}
              autoComplete="off"
            />
          </div>
          {merchants && merchants.length > 0 && (
            <div className="control-group center" style={{ padding: '0' }}>
              <ul className="merchant-list" style={{ cursor: 'pointer' }}>
                {!hasSelectedMerchant &&
                  merchants.slice(0, MERCHANTS_DROP_DOWN_LIMIT).map((merchant) => {
                    const queryWords = getRelevantWordsFromQuery(merchantName)
                    const parts = merchant.name.split(' ')

                    return (
                      <li key={merchant.id}>
                        <button className="merchant-list-item" onClick={handleMerchantSelectedFromList(merchant.id)}>
                          <h3>
                            {parts.map((part, index) => {
                              const queryWordsContainsPart = queryWords.some((word) =>
                                removeDiacritics(part).toLowerCase().includes(removeDiacritics(word).toLowerCase()),
                              )
                              if (queryWordsContainsPart) {
                                return <span key={index} className="merchant-list-item-highlighted">{`${part} `}</span>
                              }

                              return <span key={index}>{`${part} `}</span>
                            })}
                          </h3>
                          {merchant.officialName && merchant.officialName !== merchant.name && (
                            <p style={{ paddingTop: '0.5rem' }}>{merchant.officialName}</p>
                          )}
                          <p style={{ fontSize: '1.25rem', paddingTop: '0.5rem' }}>{merchant.address}</p>
                        </button>
                      </li>
                    )
                  })}
              </ul>
              {!hasSelectedMerchant && merchants.length > MERCHANTS_DROP_DOWN_LIMIT && (
                <button className="merchant-list-footer" onClick={skipMerchantSelection}>
                  <h3 style={{ fontSize: '1.75rem' }}>
                    {t('serviceProvider.serviceProviderSearch.resultsFound', {
                      merchantsFound:
                        merchants.length > MERCHANTS_DROP_DOWN_LIMIT ? MERCHANTS_DROP_DOWN_LIMIT : merchants.length,
                    })}
                  </h3>
                  <p style={{ fontSize: '1.5rem', marginTop: '1rem', cursor: 'pointer' }}>
                    <Trans i18nKey="serviceProvider.serviceProviderSearch.refineYourSearchOrContinue" />
                  </p>
                </button>
              )}
              {!hasSelectedMerchant && merchants.length > 0 && merchants.length <= MERCHANTS_DROP_DOWN_LIMIT && (
                <button className="merchant-list-footer" onClick={skipMerchantSelection}>
                  <h3 style={{ fontSize: '1.75rem' }}>
                    {t('serviceProvider.serviceProviderSearch.continueWithoutSelection')}
                  </h3>
                </button>
              )}
            </div>
          )}
        </div>

        {requiresSpecialistName && (
          <InputText
            label={
              loanPurposeId === ELoanPurpose.Medical || loanPurposeId === ELoanPurpose.Aesthetics
                ? t('serviceProvider.specialistOrDoctor')
                : t('serviceProvider.specialistName')
            }
            id="specialistName"
            maxLength={50}
            error={errors.specialistName}
            {...register('specialistName')}
          />
        )}

        <div ref={bottomRef}> </div>
      </form>
    </section>
  )
}

export default ServiceProviderForm
