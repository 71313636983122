import { FilteredCreditApplication } from '@src/api/credit-api'
import { CancelAppButton, ManageCoappButton } from '@src/components'
import RemoveCoappButton from '@src/components/RemoveCoappButton'
import RetryHardHitButton from '@src/components/RetryHardHitButton'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  creditApp: FilteredCreditApplication
}
const CreditFileLockedTile = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [toggle, setToggle] = useState<boolean>(false)
  return (
    <main className="general-message" style={{ display: 'block' }}>
      <h1>{t('decision.lockedCreditReport.header')}</h1>
      <ul className="h3" style={{ margin: '2rem auto' }}>
        {creditApp.applicant.hasLockedCreditReport && (
          <li
            style={{
              color: '#08abf0',
              border: '1px solid #08abf0',
              borderRadius: '1rem',
              padding: '1rem',
              margin: '1rem auto',
            }}
          >
            <Trans
              i18nKey="decision.lockedCreditReport.message"
              values={{
                applicantType: t('common.applicant'),
              }}
            />
          </li>
        )}
        {creditApp.coapplicant?.hasLockedCreditReport === true && (
          <li
            style={{
              color: '#08abf0',
              border: '1px solid #08abf0',
              borderRadius: '1rem',
              padding: '1rem',
              margin: '1rem auto',
            }}
          >
            <Trans
              i18nKey="decision.lockedCreditReport.message"
              values={{
                applicantType: t('common.coapplicant'),
              }}
            />
          </li>
        )}
      </ul>
      <h3>
        {t('decision.lockedCreditReport.main')}
        <button
          style={{ textDecoration: 'underline', color: '#08abf0', cursor: 'pointer', backgroundColor: 'transparent' }}
          onClick={() => setToggle(!toggle)}
        >
          {t('decision.lockedCreditReport.creditReportProvider')}
        </button>
      </h3>
      {toggle && (
        <div style={{ margin: '1rem auto' }}>
          <p
            style={{
              fontStyle: 'italic',
              border: '1px solid #08abf0',
              borderRadius: '1.5rem',
              textAlign: 'center',
              padding: '1.5rem',
            }}
          >
            {t('decision.lockedCreditReport.contactEquifax')}
          </p>
        </div>
      )}
      <h3>{t('decision.lockedCreditReport.footer')}</h3>
      <div className="btn-group mobile-reversed">
        <RetryHardHitButton creditApp={creditApp} />
      </div>
      <div className="btn-group mobile-reversed space-between">
        <CancelAppButton creditAppId={creditApp.id} />
        {creditApp.coapplicant && <RemoveCoappButton creditAppId={creditApp.id} />}
        <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={!!creditApp.coapplicant} />
      </div>
    </main>
  )
}

export default CreditFileLockedTile
