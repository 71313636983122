import { SubmitRefinancingResultDto, useGetRefinancingParams } from '@src/api/refinance-api'
import Loader from '@src/components/Loader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetCanHaveSupplementalLoanDto } from '@src/api/credit-api'
import { ESupplementalLoanRefusalReasons } from '@src/types'
import RefinancingCreditApplicationForm from './RefinanceForm'

const RefinancingCreditApplicationContainer = () => {
  const params = useParams()
  const id = params.id!
  const { t } = useTranslation()

  const [refinanceParams, isLoadingRefinance] = useGetRefinancingParams(id)
  const [canHaveSupplementalLoanDto, isFetchingCanHaveSupplementalLoanDto] = useGetCanHaveSupplementalLoanDto(id)
  const [newAppInfo, setNewAppInfo] = useState<SubmitRefinancingResultDto | null>(null)

  if (isLoadingRefinance || isFetchingCanHaveSupplementalLoanDto)
    return <Loader title={t('common.withYouShortly')} description="" />

  const canHaveSupplementalLoan = canHaveSupplementalLoanDto?.canHaveSupplementalLoan

  const canHaveSupplementalLoanReasons = canHaveSupplementalLoanDto?.refusalReasonCodes

  const supplementalLoanOnGoing = canHaveSupplementalLoanReasons?.includes(
    ESupplementalLoanRefusalReasons.OnGoingCreditApplication,
  )

  return (
    <div>
      {!canHaveSupplementalLoan && (
        <main className="general-message">
          <h4 className="subtitle">{t('common.financeApplication')}</h4>
          <h3>{supplementalLoanOnGoing ? t('supplementalLoan.onGoingCreditApp') : t('refinancing.noRefinancing')}</h3>
          {supplementalLoanOnGoing && (
            <h1>
              {t('supplementalLoan.onGoingCreditAppReferenceNumber')}{' '}
              {canHaveSupplementalLoanDto?.ongoingApplicationReferenceNumber}.
            </h1>
          )}
        </main>
      )}
      {canHaveSupplementalLoan && !newAppInfo && refinanceParams && (
        <RefinancingCreditApplicationForm
          creditApplicationId={id}
          refinancingParams={refinanceParams}
          onSubmitted={setNewAppInfo}
        />
      )}

      {newAppInfo && (
        <main className="general-message">
          <h4 className="subtitle">{t('common.financeApplication')}</h4>
          <h1>
            #{newAppInfo.referenceNumber} {t('refinancing.confirmation.inReview')}
          </h1>
          <div className="paragraph">
            <p>{t('refinancing.confirmation.messageLine1')}.</p>
            <p>{t('refinancing.confirmation.messageLine2')}</p>
          </div>
        </main>
      )}
    </div>
  )
}

export default RefinancingCreditApplicationContainer
