import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import * as yup from '@src/types/schemas/common/yup-extended'
import { useMemo } from 'react'

export function usePromoCodeYupValidator() {
  return useMemo(
    () =>
      yup.default
        .string()
        .matches(/^[a-zA-Z0-9]+$/)
        .nullable()
        .default(null)
        .EmptyStringToNull(),
    [],
  )
}

function buildWorksheetSchema(availableCredit: number, amountRequested: number, term: number) {
  return yup.default.object({
    merchantPaymentPlanId: yup.default.string().nullable().default(null),
    amountRequested: yupExtInt.double.required().default(amountRequested).min(500).max(availableCredit),
    term: yupExtInt.integer.required().default(term),
    firstPaymentOn: yup.default.string().isValidDate().required(),
    includeInsurance: yup.default.boolean().required(),
  })
}
export function useWorksheetSchema(availableCredit: number, amountRequested: number, term: number) {
  return useMemo(
    () => buildWorksheetSchema(availableCredit, amountRequested, term),
    [availableCredit, amountRequested, term],
  )
}

export type WorksheetForm = yup.default.InferType<ReturnType<typeof buildWorksheetSchema>>
