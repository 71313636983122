import { FilteredCreditApplication } from '@src/api/credit-api'
import UploadFilesTable from '@src/components/UploadFilesTable'
import { areAllDocumentsApproved } from '@src/data/credit-application-selectors'
import { FormEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  creditApp: FilteredCreditApplication
}
const HardHitConsentNoCreditReportPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const allDocsApproved = areAllDocumentsApproved(creditApp)
  const [showUploadFile, setShowUpload] = useState(allDocsApproved)
  const toggleUploadFile = (e: FormEvent) => {
    e.preventDefault()
    setShowUpload(true)
  }
  return (
    <main className="general-message" style={{ paddingTop: 0 }}>
      <form onSubmit={toggleUploadFile}>
        <div style={{ textAlign: 'center' }}>
          <h4 className="subtitle">{t('common.financeApplication')}</h4>
        </div>
        <h1>{t('identity.cannotConfirm')}</h1>
        <div className="paragraph">
          <p>
            <Trans i18nKey="identity.hereToHelp" />
          </p>
          <p>{t('identity.notifyEmail')}</p>
        </div>
      </form>

      {!showUploadFile && <UploadFilesTable creditApp={creditApp} />}
    </main>
  )
}

export default HardHitConsentNoCreditReportPage
