import { useCallback, useState } from 'react'
import { useStep } from './stepper.service'

export interface StepState {
  invalid?: boolean
  loading?: boolean
}

export type StepStateUpdater = (state: StepState) => void
export interface StepRenderProps {
  setState: StepStateUpdater
  setPrevious?: () => void
  setNext?: () => void
  isSubmitting: boolean
}

export interface StepperStepProps {
  step: string
  icon: string
  label: string
  invalid?: boolean
  loading?: boolean
  disabled?: boolean
  hideControl?: boolean
  render: (props: StepRenderProps) => React.ReactNode
}

const StepperStep = ({
  render,
  step,
  icon,
  label,
  invalid = false,
  loading = false,
  disabled = false,
  hideControl = false,
}: StepperStepProps) => {
  const [invalidState, setInvalidState] = useState<boolean>(invalid)
  const [loadingState, setLoadingState] = useState<boolean>(loading)

  const setState = useCallback<StepStateUpdater>((s) => {
    if (s.invalid !== undefined) setInvalidState(s.invalid)
    if (s.loading !== undefined) setLoadingState(s.loading)
  }, [])

  const { current, setNext, setPrevious, isSubmitting } = useStep({
    step,
    icon,
    label,
    hideControl,
    disabled,
    invalid: invalidState || invalid,
    loading: loadingState || loading,
  })
  if (current !== step) return null
  return render({ setState, setNext, setPrevious, isSubmitting })
}

export default StepperStep
