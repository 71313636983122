import translate from '@src/services/translate'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'node_modules/i18next/typescript/helpers'
import { FieldError } from 'react-hook-form'
import { EApplicantType, EDocumentStatus, EDocumentType } from './constants'

export * from './constants'

export function translateErrorCode(err: FieldError, errorParams?: TOptionsBase & $Dictionary): string {
  if (!err) return ''
  if (err.type === 'required') return translate('common.errors.required', errorParams)
  if (err.type === 'test-Sin-type') return translate('common.errors.number', errorParams)
  return err.message ? translate(err.message) : ''
}

export const EMPTY_ARRAY = []

export enum EApplicationStep {
  Credit = 'credit',
  Finaning = 'financing',
  CVT = 'CVT',
  Payout = 'payout',
}

export interface RequiredDocument {
  id: string
  applicantType: EApplicantType
  typeId: EDocumentType
  nameFr: string
  nameEn: string
  note: string
  subKey: string
  requiredBeforeStep: EApplicationStep
}

export type RequiredDocumentWithStatus = RequiredDocument & {
  status: EDocumentStatus
  refusalReason: string | null
}

export type CustomError = Error & { id?: string }
