import { FilteredCreditApplication } from '@src/api/credit-api'
import { useTranslation } from 'react-i18next'
import CustomerFeedbackSubmit from '../HardHitApprovedResultPage/components/CustomerFeedBackSubmit'

interface Props {
  creditApp: FilteredCreditApplication
}
const CompletedAppPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const msg = creditApp.loanCreated ? 'common.loanCreated' : 'common.awaitingContractReview'
  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.goodNews')}</h4>
      <div>
        <h3>{t(msg)}</h3>
      </div>
      <CustomerFeedbackSubmit creditApp={creditApp} />
    </main>
  )
}

export default CompletedAppPage
