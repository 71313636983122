import { createContext, useContext, useMemo, useState } from 'react'
import { getIdsFromUrl } from '@src/services/query-string'
import { OriginationTracking } from '@src/types/origination'

export interface TAppStore {
  merchantId: string
  setMerchantId: (id: string) => void
  origination: OriginationTracking | null
  setOrigination: (origination: OriginationTracking) => void
}

export const AppContext = createContext<TAppStore | null>(null)

export function useAppStoreProvider(initialOrigination: OriginationTracking | null) {
  const [origination, setOrigination] = useState<OriginationTracking | null>(initialOrigination)
  const idsFromUrl = getIdsFromUrl()
  const [merchantId, setMerchantId] = useState<string>(idsFromUrl.merchantId)

  return useMemo(
    () => ({
      merchantId,
      setMerchantId,
      origination,
      setOrigination,
    }),
    [merchantId, setMerchantId, origination, setOrigination],
  )
}

export function useAppStore(): TAppStore {
  return useContext(AppContext)!
}
