import { FilteredCreditApplication } from '@src/api/credit-api'
import { hasMissingDocuments, mustAskCoapplicantSIN } from '@src/data/credit-application-selectors'
import { EApplicationStep, EDecision } from '@src/types'
import { ErrorPage } from '@src/components'
import DecisionningErrorTile from '../components/DecisionningErrorTile'
import DeclinedDecisionForm from '../components/DeclinedDecisionForm'
import UnderReviewTile from '../components/UnderReviewTile'
import CoappSinForm from './components/CoappSinForm'
import CreditFileLockedTile from './components/CreditFileLockedTile'
import FailedToObtainCoappCreditReportForm from './components/FailedToObtainCoappCreditReportForm'
import RequiredDocForDecisionForm from './components/RequiredDocForDecisionForm'

interface Props {
  creditApp: FilteredCreditApplication
}

const HardHitUnapprovedResultPage = ({ creditApp }: Props) => {
  const requiresCoappSin = mustAskCoapplicantSIN(creditApp)

  const hasMissingDocForCreditDecision = hasMissingDocuments(creditApp, EApplicationStep.Credit)

  if (creditApp.applicant.hasLockedCreditReport || creditApp.coapplicant?.hasLockedCreditReport === true)
    return <CreditFileLockedTile creditApp={creditApp} />

  if (creditApp.coapplicant && !creditApp.coapplicant.hasCreditReport)
    return <FailedToObtainCoappCreditReportForm creditApp={creditApp} />

  if (
    creditApp.finalDecision.decision === EDecision.Error ||
    (!creditApp.applicant.hasCreditReport && !creditApp.coapplicant?.hasCreditReport)
  )
    return <DecisionningErrorTile />

  if (creditApp.finalDecision.decision === EDecision.Refused) return <DeclinedDecisionForm creditApp={creditApp} />

  if (requiresCoappSin) return <CoappSinForm creditApp={creditApp} />

  if (creditApp.finalDecision.decision === EDecision.ManualRevision && !hasMissingDocForCreditDecision)
    return <UnderReviewTile />

  if (creditApp.finalDecision.decision === EDecision.ManualRevision && hasMissingDocForCreditDecision)
    return <RequiredDocForDecisionForm creditApp={creditApp} />

  return <ErrorPage />
}

export default HardHitUnapprovedResultPage
