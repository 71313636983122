import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'available-credit'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

export interface AvailableCredit {
  availableAmount: number
  expiresOn: Date
}

const getAvailableCredit = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await apiClient.get<AvailableCredit>(
    `/api/creditApplication/${encodeURIComponent(id)}/availablecredit`,
  )
  return response.data
}

export function useGetAvailableCredit(
  creditApplicationId: string,
  enabled: boolean,
): [AvailableCredit | undefined, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getAvailableCredit,
    enabled: !!creditApplicationId && enabled,
  })

  return [data, isFetching]
}
